import React from "react";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { cn, buildImageObj } from "../lib/helpers";
import { Link } from "gatsby";
import Icon from "./icon";
import PortableText from "./portableText";
import { useLocation } from '@reach/router';

import * as styles from "../styles/components/post-page-header.module.css";

const PageHeader = (props) => {

  const {
    title,
    description,
    button,
    landingTitle,
    image
  } = props;

  const url = useLocation().href;

  return (
    <div className={styles.root}>
      <div className={cn("grid", styles.grid)}>
        <div className={cn("col-12 ", (image && image.asset) ? "col-md-6" : styles.noImg, styles.col, styles.text)}>
          <Container>
            <div className={styles.contentWrap}>
              <Link className={styles.back} to="/library"><Icon symbol="arrow-left" />{landingTitle}</Link>
              {title && (<h1 className={cn("h4", styles.title)}>{title}</h1>)}
              {description && (<PortableText blocks={description} />)}
              {button && (button.url || (button.document && button.document.asset)) && (
                <>
                  {button.document && button.document.asset ? (
                    <a href={button.document.asset.url + "/" + button.document.asset.originalFilename} className={cn("btn", button.secondaryButton && "secondary", styles.button)} target="_blank" rel="noreferrer" data-analytics-id="PW-2" data-analytics-category="Button Click" data-analytics-action="Whitepaper download" data-analytics-label={url}>
                      {button.text}
                      <Icon symbol="arrow-right" />
                    </a>
                  ) : (
                    <>
                      {button.blank ? (
                        <a href={button.url} className={cn("btn", button.secondaryButton && "secondary", styles.button)} target="_blank" rel="noreferrer" data-analytics-id="PW-2" data-analytics-category="Button Click" data-analytics-action="Whitepaper download" data-analytics-label={url}>
                          {button.text}
                          <Icon symbol="arrow-right" />
                        </a>
                      ) : (
                        <Link to={button.url} className={cn("btn", button.secondaryButton && "secondary", styles.button)} data-analytics-id="PW-2" data-analytics-category="Button Click" data-analytics-action="Whitepaper download" data-analytics-label={url}>
                          {button.text}
                          <Icon symbol="arrow-right" />
                        </Link>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </Container>
        </div>
        {image && image.asset && (
          <div className={cn("col-12 col-md-6", styles.col, styles.image)}>
            <img
              src={imageUrlFor(buildImageObj(image))
                .auto("format")
                .url()}
              alt={image.alt}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PageHeader;
