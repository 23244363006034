import React from "react";
import { graphql } from "gatsby";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import { Helmet } from 'react-helmet';
import { toPlainText } from "../lib/helpers";

import PageHeader from "../components/library-page-header";
import PostMain from "../components/post-main";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query LibraryTemplateQuery($id: String!) {
    libraryLanding: sanityLibraryLanding {
      title
      themePurple
    }

    library: sanityLibrary(id: { eq: $id }) {
      id
      title
      _rawDescription
      thumbnail {
        ...SanityImage
        alt
      }
      button {
        text
        url
        secondaryButton
        blank
        document {
          asset {
            url
            originalFilename
          }
        }
      }
      flexibleContent {
        ... on SanityFc2ColumnImage {
          _key
          _type
          imageLeft {
            ...SanityImage
            alt
            caption
          }
          imageRight {
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcContentBlock {
          _key
          _type
          _rawContent
        }
        ... on SanityFcImage {
          _key
          _type
          fullwidth
          image {
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcVideo {
          _key
          _type
          caption
          url
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }
  }
`;

const LibraryTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  const libraryLanding = (data || {}).libraryLanding;
  const library = (data || {}).library;

  const title = library.seo?.title || library.title;
  const description = library.seo?.description || toPlainText(library._rawDescription);
  const image = library.seo?.thumbnail || library.thumbnail;
  const autoSiteTitle = !library.seo?.title;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      <Helmet>
        <html className={libraryLanding.themePurple ? "theme-purple" : undefined} lang="en" />
      </Helmet>

      {library.title && (<PageHeader title={library.title} description={library._rawDescription} button={library.button} landingTitle={libraryLanding.title} image={library.thumbnail} />)}
      {library.flexibleContent && (<PostMain content={library.flexibleContent} title={library.title} />)}
    </Layout>
  );
};

export default LibraryTemplate;
